$rct-icon-color: #33c !default;
$rct-icon-font-family: "Font Awesome 5 Free", "FontAwesome", sans-serif;
$rct-label-hover: rgba($rct-icon-color, .1) !default;
$rct-label-active: rgba($rct-icon-color, .15) !default;
$rct-clickable-hover: rgba($rct-icon-color, .1) !default;
$rct-clickable-focus: rgba($rct-icon-color, .2) !default;

// Force ASCII output until Sass supports it
// https://github.com/sass/dart-sass/issues/568
@function unicode($str) {
  @return unquote("\"") + unquote(str-insert($str, "\\", 1)) + unquote("\"");
}

.react-checkbox-tree {
  display: flex;
  flex-direction: row-reverse;
  font-size: 13px;

  > ol {
    flex: 1 1 auto;
  }

  ol {
    margin: 0;
    padding-left: 0;
    list-style-type: none;

    ol {
      padding-left: 24px;
    }
  }

  button {
    line-height: normal;
    color: inherit;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .rct-bare-label {
    cursor: default;
  }

  label {
    margin-bottom: 0;
    cursor: pointer;

    &:hover {
      background: $rct-label-hover;
    }

    &:active,
    &:focus {
      background: $rct-label-active;
    }
  }

  &:not(.rct-native-display) input {
    display: none;
  }

  &.rct-native-display input {
    margin: 0 5px;
  }

  .rct-icon {
    display: inline-block;
    text-align: center;
    text-rendering: auto;
    font-family: $rct-icon-font-family;
    font-weight: normal;
    font-variant: normal;
    font-style: normal;
  }
}

.rct-disabled > .rct-text > label {
  opacity: .75;
  cursor: not-allowed;

  &:hover {
    background: transparent;
  }

  &:active {
    background: transparent;
  }
}

.rct-text {
  display: flex;
  align-items: center;
}

.rct-options {
  flex: 0 0 auto;
  margin-left: .5rem;
  text-align: right;
}

.rct-option {
  opacity: .75;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0 4px;
  font-size: 18px;

  &:hover {
    opacity: 1;
  }

  + .rct-option {
    margin-left: 2px;
  }
}

.rct-collapse,
.rct-checkbox,
.rct-node-icon {
  padding: 0 5px;

  * {
    display: inline-block;
    margin: 0;
    width: 14px;
  }
}

.rct-collapse {
  align-self: stretch;
  border: 0;
  background: none;
  line-height: normal;
  color: inherit;
  font-size: 12px;

  &.rct-collapse-btn {
    cursor: pointer;
  }

  > .rct-icon-expand-close {
    opacity: .5;

    &:hover {
      opacity: 1;
    }
  }
}

.rct-native-display .rct-checkbox {
  display: none;
}

.rct-node-clickable {
  cursor: pointer;

  &:hover {
    background: $rct-clickable-hover;
  }

  &:focus {
    outline: 0;
    background: $rct-clickable-focus;
  }
}

.rct-node-icon {
  color: $rct-icon-color;
}

.rct-title {
  padding: 0 5px;
}

// Font Awesome 4 style
.rct-icons-fa4 {
  .rct-icon-expand-close::before {
    content: unicode("f054");
  }

  .rct-icon-expand-open::before {
    content: unicode("f078");
  }

  .rct-icon-uncheck::before {
    content: unicode("f096");
  }

  .rct-icon-check::before {
    content: unicode("f046");
  }

  .rct-icon-half-check::before {
    opacity: .5;
    content: unicode("f046");
  }

  .rct-icon-leaf::before {
    content: unicode("f016");
  }

  .rct-icon-parent-open::before {
    content: unicode("f115");
  }

  .rct-icon-parent-close::before {
    content: unicode("f114");
  }

  .rct-icon-expand-all::before {
    content: unicode("f0fe");
  }

  .rct-icon-collapse-all::before {
    content: unicode("f146");
  }
}

// Font Awesome 5 style
.rct-icons-fa5 {
  .rct-icon-expand-close::before {
    font-weight: 900;
    content: unicode("f054");
  }

  .rct-icon-expand-open::before {
    font-weight: 900;
    content: unicode("f078");
  }

  .rct-icon-uncheck::before {
    content: unicode("f0c8");
  }

  .rct-icon-check::before {
    content: unicode("f14a");
  }

  .rct-icon-half-check::before {
    opacity: .5;
    content: unicode("f14a");
  }

  .rct-icon-leaf::before {
    content: unicode("f15b");
  }

  .rct-icon-parent-open::before {
    content: unicode("f07c");
  }

  .rct-icon-parent-close::before {
    content: unicode("f07b");
  }

  .rct-icon-expand-all::before {
    content: unicode("f0fe");
  }

  .rct-icon-collapse-all::before {
    content: unicode("f146");
  }
}

// RTL support
.rct-direction-rtl {
  direction: rtl;

  ol ol {
    padding-right: 24px;
    padding-left: 0;
  }

  &.rct-icons-fa4 .rct-icon-expand-close::before {
    content: unicode("f105");
  }

  &.rct-icons-fa5 .rct-icon-expand-close::before {
    content: unicode("f053");
  }
}
